import { TemplateResult, render } from 'lit/html.js';
import ModalTemplate from './template';

export default abstract class JrModal implements IJrModal {
  private OPEN_CLASS = 'modal-open';
  private JS_BACKDROP_CLASS = 'js-modal-backdrop';
  private JS_CLASS = 'js-modal';
  private modalSelector: string;
  private modalClasses: string;
  private jrConfigGlobal: JrConfigGlobal = window.jrConfigGlobal;

  constructor(
    protected readonly customModalClass: string,
    protected readonly wrapperClass: string,
    protected readonly backdropClickable: 'static' | '' = '',
    protected handleAfterClose = async (): Promise<void> => { }
  ) {
    this.modalSelector = this.customModalClass ? `js-${this.customModalClass}` : this.JS_CLASS;
    this.modalClasses = `${this.customModalClass} js-${this.customModalClass}`;
  }

  abstract getContent(): Promise<TemplateResult>;

  abstract openedCallback(): void;

  close = async (): Promise<void> => {
    const modalInstance = JrModal.getModalInstance(this.modalSelector);
    if (modalInstance) {
      const backdropHtmlElement = <HTMLElement>modalInstance.nextElementSibling;
      const isBackdrop = backdropHtmlElement?.classList.contains(this.JS_BACKDROP_CLASS);

      modalInstance.classList.remove('jr-modal--fade-in');
      modalInstance.classList.add('jr-modal--fade-out');

      if (isBackdrop) {
        JrModal.hideBackdrop(backdropHtmlElement);
      }
      this.removeModalWrapperAndNodes();
      this.removeBodyClass();
    }

    await this.handleAfterClose();
  }

  render = async (): Promise<void> => {
    await this.renderModal(<HTMLElement>document.querySelector(`.${this.wrapperClass}`));
  };

  init = async (): Promise<void> => {
    const wrapperHtmlElement = <HTMLElement>document.querySelector(`.${this.wrapperClass}`);

    if (!wrapperHtmlElement) {
      JrModal.createWrapper(this.wrapperClass);
    }

    this.addBodyClass();
    await this.renderModal(<HTMLElement>document.querySelector(`.${this.wrapperClass}`));
    this.openedCallback();

    this.keydownListener();
    if (this.backdropClickable !== 'static' && this.jrConfigGlobal.feature.modalBackdropClickable) {
      this.closeListener(wrapperHtmlElement);
    }
  }

  private renderModal = async (wrapperHtmlElement: HTMLElement): Promise<void> => {
    render(this.composeHtml(await this.getContent()), wrapperHtmlElement);
  }

  private composeHtml = (template: TemplateResult): TemplateResult => {
    return ModalTemplate(
      template,
      {
        modal: this.modalClasses,
        backdropOpacity: this.jrConfigGlobal?.modal?.overlayOpacity
      }
    );
  }

  private static createWrapper = (wrapperClass: string, element: HTMLElement = document.body): void => {
    const wrapper = document.createElement('div');
    wrapper.classList.add(wrapperClass);

    element.appendChild(wrapper);
  }

  private addBodyClass = (): void => {
    document.body.classList.add(this.OPEN_CLASS);
  }

  private removeBodyClass = (): void => {
    document.body.classList.remove(this.OPEN_CLASS);
  }

  private static hideBackdrop = (backdropHtmlElement: HTMLElement): void => backdropHtmlElement.classList.remove('in');

  private static getModalInstance = (modalSelector: string): HTMLElement | null =>
    document.querySelector(`.${modalSelector}`);

  private closeListener = (wrapperHtmlElement: HTMLElement | null): void => {
    if (wrapperHtmlElement) {
      wrapperHtmlElement.addEventListener('click', (event: Event) => {
        const targetElement = <HTMLElement>event.target;

        if (targetElement.classList.contains(`${this.modalSelector}`) || targetElement.classList.contains(`${this.JS_BACKDROP_CLASS}`)) {
          this.close();
        };
      });
    }
  }

  private keydownListener = (): void => {
    document.addEventListener('keydown', (event: KeyboardEvent) => {
      if (event.key?.toLowerCase() === 'escape') {
        this.close();
      }
    }, false);
  }

  private removeModalWrapperAndNodes = (): void => {
    setTimeout(() => {
      const wrapperHtmlElement = <HTMLElement>document.querySelector(`.${this.wrapperClass}`);
      wrapperHtmlElement.remove()
    }, 500);
  }
}
