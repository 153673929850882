export const jrLocalStorage = {

  setItem(key: string, value: unknown): void {
    localStorage.setItem(key, JSON.stringify(value));
  },

  getItem<T>(key: string): T | null {
    const data: string | null = localStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    }
    return null;
  },

  removeItem(key: string): void {
    localStorage.removeItem(key)
  }
}

export const jrSessionStorage = {

  setItem(key: string, value: unknown): void {
    sessionStorage.setItem(key, JSON.stringify(value));
  },

  getItem<T>(key: string): T | null {
    const data: string | null = sessionStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    }
    return null;
  },

  removeItem(key: string): void {
    sessionStorage.removeItem(key)
  }
}

export enum JrSessionStorageKeys {
  EXIT_INTENT_POPUP_WAS_DISPLAYED = 'exit_intent_popup_was_displayed'
}

export enum JrLocalStorageKeys {
  INTERSTITIAL_ADS_LAST_DISPLAYED = '__lsv__'
}