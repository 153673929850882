import { html, TemplateResult } from "lit/html.js";
import { repeat } from "lit/directives/repeat.js";

export const MySearchesComponent = (props: IMySearchesComponent): TemplateResult => {

  const { mySearches, messages: { MySearchesPageTitle } } = props;

  return html`
  <div class="sidebar-tool sidebar-mysearches">
  <h3 id="aside-mysearches" class="h5 filter-title">
    <a href="/mysearches">${MySearchesPageTitle}</a>
  </h3>
    <div class="c-content">
      <ul class="unstyled">
      ${repeat(mySearches, (mySearch: IMySearch) =>
    mySearch, (mySearch: IMySearch, index: number) => html`
        <li class="statement ${index === 0 ? 'current' : ''}">
          <i class="icon-angle-right"></i>
          <a href=${mySearch.url} data-google-interstitial="false" class="my-searches-link">
            <span class="label-wrapper">
            <span class="what">${mySearch.search.what}</span>
            ${mySearch.search.what && mySearch.search.where ? ' / ' : ''}
            <span class="where">${mySearch.search.where}</span>
            </span>
          </a>
        </li>
      `)}
      </ul>
    </div>
</div>`;
}