import { JtagService } from '../../ts/service/jtag-service.ts';
import {
  isSubscriptionPopupVisibile,
  subscriptionCookieInfo,
} from '../../ts/service/subscription-popup-service.ts';
import { readCookie } from '../../ts/utils/cookie.ts';
import jrCookieConsentTipTemplate from '../../view/angular/common/cookie-consent.tpl.html';

angular
  .module('jrCookieConsent', ['jrConfig', 'ipCookie'])
  .value('isSubscriptionPopupVisibile', isSubscriptionPopupVisibile)
  .value('subscriptionCookieInfo', subscriptionCookieInfo)
  .value('readCookie', readCookie)
  .factory('CookieConsent', CookieConsentFactory)
  .factory('ManageCookieConsent', ManageCookieConsentFactory)
  .controller('CookieConsentController', CookieConsentController)
  .directive('jrCookieConsentTip', jrCookieConsentTip);

function CookieConsentFactory(Messages, jrGlobalConfig) {
  const CookieConsent = function () {
    this.messages = Messages;
    this.infoPath = jrGlobalConfig.infoPath;
    this.cssClasses =
      jrConfigGlobal.isMobile &&
      (jrConfigGlobal.page.isSearch || jrConfigGlobal.page.isJobPreviewPage)
        ? 'cookie-consent__cookie-law-on-top'
        : '';
  };

  return CookieConsent;
}

function ManageCookieConsentFactory(ipCookie, CookieClientConstants) {
  const setCookie = function (name, value) {
    ipCookie(name, value, {
      expires: 8760,
      expirationUnit: 'hours',
      path: '/',
    });
  };

  const hidePopup = function () {
    const cookieConsentValues = ipCookie('JEC') || {};
    const header = document.querySelector('.js-header-wrapper');
    const cookieConsentPopup = document.querySelector('.js-cookie-consent-wrapper');

    if (header) {
      header.classList.remove('cookie-opened');
    }

    if (cookieConsentPopup) {
      cookieConsentPopup.classList.remove('opened');
    }

    setCookie(
      CookieClientConstants.name,
      angular.extend(cookieConsentValues, {
        clc: true,
      })
    );

    JtagService.trackCustomEvent('cookie_law_consensus');
  };

  return {
    hidePopup,
  };
}

function CookieConsentController($scope, CookieConsent) {
  return {
    cookieConsent: new CookieConsent(),
  };
}

function jrCookieConsentTip(
  CookieClientConstants,
  ipCookie,
  ManageCookieConsent,
  isSubscriptionPopupVisibile,
  subscriptionCookieInfo,
  readCookie
) {
  return {
    restrict: 'E',
    replace: true,
    template: jrCookieConsentTipTemplate,
    link,
  };

  function link(scope, element, attrs) {
    const { keys } = CookieClientConstants;
    const header = document.querySelector('header');
    const cookieConsentValues = ipCookie('JEC') || {};
    const cookieConsentBanner = document.getElementsByClassName('js-cookie-consent');
    const isPrivacyPolicyPage = angular
      .element(document.querySelector('body'))
      .hasClass('privacy-policy-page');

    const cookieValue = readCookie(subscriptionCookieInfo.COOKIE_NAME) || null;

    scope.showSubscriptionPopup = isSubscriptionPopupVisibile(cookieValue);

    if (!isPrivacyPolicyPage && !scope.showSubscriptionPopup) {
      document.addEventListener(
        'click',
        function (event) {
          if (
            cookieConsentBanner.length > 0 &&
            event.target !== cookieConsentBanner[0] &&
            !cookieConsentBanner[0].contains(event.target) &&
            cookieConsentValues[keys.COOKIE_LAW_CONSENT] !== true
          ) {
            hideBanner();
          }
        },
        false
      );
    }

    if (cookieConsentValues[keys.COOKIE_LAW_CONSENT] !== true && !isPrivacyPolicyPage) {
      showBanner();
    }

    scope.close = function () {
      hideBanner();
    };

    function hideBanner() {
      const proposalInput = document.querySelector('.proposal-form input[name="email"]');
      if (proposalInput) {
        proposalInput.focus();
      }

      ManageCookieConsent.hidePopup();
    }

    function showBanner() {
      angular.element(header).addClass('cookie-opened');
      element.addClass('opened');
    }
  }
}

CookieConsentFactory.$inject = ['Messages', 'jrGlobalConfig'];
ManageCookieConsentFactory.$inject = ['ipCookie', 'CookieClientConstants'];
CookieConsentController.$inject = ['$scope', 'CookieConsent'];
jrCookieConsentTip.$inject = [
  'CookieClientConstants',
  'ipCookie',
  'ManageCookieConsent',
  'isSubscriptionPopupVisibile',
  'subscriptionCookieInfo',
  'readCookie',
];
