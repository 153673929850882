import WelcomeOrConfirmationReminderModal from ".";

export const loadWelcomeOrConfirmationReminderModal = ({ emailAddress, source, shouldReload = false }: IWelcomeOrConfirmationReminderComponentLoader): void => {
  let modalClass = 'welcome-modal';

  switch (source) {
    case 'google_one_tap':
      modalClass = 'google-one-tap-confirm-subscription-modal';
      break;
    case 'confirmation_reminder':
      modalClass = 'confirm-reminder-modal';
      break;

    default:
      break;
  }

  new WelcomeOrConfirmationReminderModal(
    modalClass,
    'jr-modal-wrapper',
    emailAddress,
    source,
    shouldReload,
  ).init();
}
