import { encodeUri } from ".";
import { radiusFormatter } from "./formatters";

const getParameterByName = (name: string): string => {
  const regex = new RegExp(
    `[\\?&]${name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]')}=([^&#]*)`
  );
  const results = regex.exec(window.location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export const getSubscribePayload = (
  params: ISubscribe,
  jrConfigGlobal: Readonly<JrConfigGlobal> = window.jrConfigGlobal): string => {
  const {
    what,
    where,
    email,
    source,
    password,
    referrer,
    marketingConsent,
    firstname,
    lastname,
    captchaResponse,
    radius
  } = params;
  const url: string[] = [];

  url.push(`w=${encodeUri(what)}`);
  url.push(`l=${encodeUri(where)}`);
  url.push(
    `r=${jrConfigGlobal.feature.distanceFilterEnabled && !!where ? encodeUri(radiusFormatter(radius)) : ''
    }`
  );
  url.push('ConditionAcceptance=on');
  url.push(`Email=${encodeUri(email)}`);
  url.push(`ParentShardId=${jrConfigGlobal.jobletter.jobseeker.parentShardId}`);
  url.push(`ParentUserId=${jrConfigGlobal.jobletter.jobseeker.parentUserId}`);
  url.push('DemAcceptance=true');
  url.push(`s=${source || ''}`);
  url.push(`subscriptionSource=${source || ''}`);
  url.push(`password=${password || ''}`);
  url.push(`referrer=${referrer || ''}`);
  url.push(`firstname=${firstname || ''}`);
  url.push(`lastname=${lastname || ''}`);
  url.push(`tpmaccepted=${JSON.stringify(marketingConsent) || ''}`);
  if (captchaResponse) {
    url.push(`captchaUserResponse=${captchaResponse || ''}`);
  }

  return url.join('&');
}

export const getAddMySearchesPayload = (mySearches: IModalMySearch[], email: string): string => (
  [
    ...mySearches.map((mySearch, index) => `w${index + 2}=${encodeUri(mySearch.what)}&l${index + 2}=${encodeUri(mySearch.where)}`),
    `Email=${email}`
  ])
  .join('&');

export const getUpdateProfilePayload = ({
  firstName = '',
  lastName = '',
  gdprJobMarketingAccepted,
  password = '',
  confirmPassword = '',
  oldPassword = 'old-password' }: IMyprofilePayload): string => {
  const url: string[] = [];

  if (firstName) url.push(`firstname=${encodeUri(firstName)}`);
  if (lastName) url.push(`lastname=${encodeUri(lastName)}`);
  if (gdprJobMarketingAccepted) url.push(`gdprjobmarketingaccepted=${gdprJobMarketingAccepted}`);
  url.push(`password=${password}`);
  url.push(`old-password=${oldPassword}`);
  url.push(`confirm-password=${confirmPassword}`);

  return url.join('&');
}

export const getTpmPayload = (params: ITpmConsentPayload): string => {
  const {
    tpmaccepted,
  } = params;
  const url: string[] = [];
  if (tpmaccepted !== null) {
    url.push(`tpmaccepted=${JSON.stringify(tpmaccepted) || null}`);
  }
  return url.join('&');
}

export const getLoginPayload = ({ email, password }: ILoginPayload): string => {
  const url: string[] = [];

  url.push(`login-email=${encodeUri(email)}`);
  url.push(`login-password=${password}`);

  return url.join('&');
}

export const getRecoverPasswordPayload = (email: string): string => {
  const url: string[] = [];
  url.push(`recover-password-email=${encodeUri(email)}`);

  return url.join('&');
}

export const getResetPasswordPayload = ({ password = '', confirmPassword = '' }: IResetPasswordPayload): string => {
  const url: string[] = [];

  url.push(`reset-password=${password}`);
  url.push(`reset-password-confirm=${confirmPassword}`);
  url.push(`token=${getParameterByName('token')}`);

  return url.join('&');
}

export const getChangePrivacyPolicyPayload = ({ jobseeker_id, from, core_services_accepted, job_marketing_accepted }: IPrivacyPolicyChangePayload): string => {
  const url: string[] = [];

  url.push(`jobseeker_id=${jobseeker_id}`);
  url.push(`from=${from}`);
  url.push(`core_services_accepted=${core_services_accepted}`);
  url.push(`job_marketing_accepted=${job_marketing_accepted}`);

  return url.join('&');
}

export const getTmsWidgetPayload = ({ localeCountry, whereTerm, whatTerm, isMobile, pageUuid }: ITmsWidgetPayload): string => {
  const url: string[] = [];

  url.push(`country=${localeCountry}`);
  url.push(`location=${whereTerm}`);
  url.push(`what=${whatTerm}`);
  url.push(`ismobile=${isMobile}`);
  url.push(`pageuuid=${pageUuid}`);

  return url.join('&');
}

export const getFavouriteJobsPayload = ({ jobseekerId, countryCode, searchResults }: IGetFavouriteJobsPayload): string => {
  const url: string[] = [];

  url.push(`jobseekerId=${jobseekerId}`);
  url.push(`country=${countryCode}`);

  const keys = typeof searchResults === 'object' ? Object.keys(searchResults) : [];
  keys.forEach((advertId) => url.push(`advertId=${advertId}`))

  return url.join('&');
}

export const getAddFavouriteJobPayload = ({ jobseekerId, advertId, countryCode, where }: IAddFavouriteJobPayload): string => {
  return JSON.stringify({
    jobseekerId,
    advertId,
    location: where,
    country: countryCode,
  })
}