import { html, TemplateResult } from 'lit/html.js';
import { classMap } from "lit/directives/class-map.js";

const ModalTemplate = (content: TemplateResult, htmlClasses: ModalTemplateClasses): TemplateResult => {
  const backdropClasses = {
    [`opacity-${htmlClasses.backdropOpacity}`]: !!htmlClasses.backdropOpacity
  };

  return html`
    <div class="jr-modal modal ${htmlClasses.modal} jr-modal--fade-in" tabindex="-1" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          ${content}
        </div>
      </div>
    </div>
    <div class="${classMap(backdropClasses)} modal-backdrop jr-modal-backdrop fade in js-modal-backdrop"></div>`;
}

export default ModalTemplate;