import NavigationService from "@services/navigation-service";

export const encodeUri = (uri: string) => (uri ? window.encodeURIComponent(uri) : '');


const isLatinCharacter = (char: string): boolean =>
  // eslint-disable-next-line no-control-regex
  /[^\u0000-\u007F]/.test(char);

export const capitalize = (text: string): string =>
  text.toLowerCase().replace(/([^a-zA-Z]|^)([a-zA-Z])/g, (match: string, p1: string, p2: string, offset: number): string => {
    if (isLatinCharacter(p1)) {
      return offset === 0 ? p1.toUpperCase() + p2 : match
    }
    return p1 + p2.toUpperCase()
  });

export const replaceStringPlaceholders = (text: string, values: string[]) =>
  text.replace(/\$(\d)/g, (_, p1) => values[p1 - 1] || '');

export const decodeHTMLEntities = (text: string): string => {
  const textArea = document.createElement('textarea');
  textArea.innerHTML = text;

  return textArea.value;
};

export const focusHandler = (elementClass: string): void => {
  const inputElement = <HTMLInputElement>document.querySelector(elementClass);
  if (inputElement) {
    inputElement.focus();
  }
}

export const pageShowListener = (callback: (jrConfigGlobal: Readonly<JrConfigGlobal>) => Promise<void>): void => {
  window.addEventListener('pageshow', (event) => {
    if (event.persisted) {
      callback(window.jrConfigGlobal);
    }
  });
}

export const isValidEmail = (email: string): boolean =>
  /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/.test(email.toLowerCase())

export const isValidDomain = (email: string, global: Window = window): boolean => {
  const domain: string = email.split('@')[1];
  const blockedDomainList = global.jrConfigGlobal.subscription.invalidEmailDomains?.split(',').map((res: string) => res.trim());
  return blockedDomainList ? !(blockedDomainList.some((res: string) => res === domain) && isValidEmail(email)) : false;
}

export const getHtmlElementByClass = (className: string): HTMLElement =>
  <HTMLElement>document.querySelector(`.${className}`);

export const getScrollPercentage = (): number =>
  Math.round(window.scrollY / (document.body.scrollHeight - window.innerHeight) * 100);

export const footerCheckFixable = (): void => {
  setTimeout(() => {
    const footer = document.getElementById('footer');

    if (footer && window.innerHeight > footer.getBoundingClientRect().bottom + 50) {
      footer.classList.add('fixed');
    }
  });
}

export const getURLParameter = (name: string) => {
  return (
    decodeURIComponent(
      (new RegExp(`[?|&]${name}=([^&;]+?)(&|#|;|$)`).exec(window.location.search) || [
        '',
        '',
      ])[1].replace(/\+/g, '%20')
    ) || null
  );
}

export const getFilterUrl = (filterType: string, filterValue: string) => {

  const filter = new RegExp(`${filterType}=[^&]+`);

  const url =
    filterType === 'shm'
      ? NavigationService.removeQueryStringParameter('p', window.location.href)
      : window.location.href;

  return getURLParameter(filterType)
    ? url.replace(filter, `${filterType}=${filterValue}`)
    : `${url + (url.indexOf('?') > -1 ? '&' : '?') + filterType}=${filterValue}`;
}