import { LinkComponent } from "@components/common";
import { html, TemplateResult } from "lit/html.js";
import { when } from "lit/directives/when.js";
import { unsafeHTML } from "lit/directives/unsafe-html.js";

const WelcomeModalTemplate = (props: IWelcomeModalTemplateProps): TemplateResult => {
  const {
    messages: {
      SingleOptinWelcomePopupMessageP1,
      SingleOptinWelcomePopupMessageP2,
      SingleOptinWelcomePopupMessageCTA,
      JobSeekerConfirmDialogBottomDescP1LinkToInbox,
      JobSeekerConfirmDialogBottomDescP2LinkToInbox,
      OneTapGoToMailTitle,
      OneTapGoToMailDescription,
      OneTapGoToMailButton,
      JobSeekerConfirmationDialogOkButton,
      JobSeekerConfirmDialogDescP1LinkToInbox,
      JobSeekerConfirmDialogDescP2LinkToInbox,
      SubscriptionNewLocationAcknowledgment
    },
    linkToInboxProviderUrl,
    email,
    source,
    LocationPreferenceAcknowledgment,
    close
  } = props;

  let messageP1 = SingleOptinWelcomePopupMessageP1;
  let messageP2 = SingleOptinWelcomePopupMessageP2;
  let ctaMessage = SingleOptinWelcomePopupMessageCTA;

  if (source === 'confirmation_reminder') {
    messageP1 = JobSeekerConfirmDialogDescP1LinkToInbox;
    messageP2 = JobSeekerConfirmDialogDescP2LinkToInbox;
    ctaMessage = JobSeekerConfirmationDialogOkButton;
  }

  const googleOneTaplinkProps: ILinkElement = {
    href: linkToInboxProviderUrl || '',
    target: '_blank',
    cssClasses: 'jr-button',
    linkText: OneTapGoToMailButton
  }

  return source === 'google_one_tap' ? html`
        <h4 class="go-to-mail__title">${unsafeHTML(OneTapGoToMailTitle)}</h4>
        <p class="go-to-mail__description">${unsafeHTML(OneTapGoToMailDescription)}</p>
        ${LinkComponent(googleOneTaplinkProps)}
  ` : html` <div class="modal-body link-to-inbox confirm-subscription">
              <div class="proposal-claim">
                <div class="item">
                  <span>${messageP1}</span>
                  <strong data-hj-suppress>${email}</strong>
                </div>
                <div class="item">
                  <span>${messageP2}</span>
                </div>
                ${when(LocationPreferenceAcknowledgment, () => html`
                  <div class="item">
                    <span>${SubscriptionNewLocationAcknowledgment}</span>
                  </div>
                `)}
              </div>
            ${linkToInboxProviderUrl ?
      html` <div class="row-fluid btn-container">
                <a autofocus @click=${close} target="_blank" href="${linkToInboxProviderUrl}" class="jr-button jr-button-material">
                  ${ctaMessage}
                </a>
              </div>
              <div class="row-fluid disclaimer-container">
                <span>${JobSeekerConfirmDialogBottomDescP1LinkToInbox}</span>
                <span>${JobSeekerConfirmDialogBottomDescP2LinkToInbox}</span>
            </div>` :
      html` <div class="row-fluid btn-container">
                <button autofocus @click=${close} class="jr-button jr-button-material">
                  ${ctaMessage}
                </button>
              </div>`}
            </div>
    `;
};

export default WelcomeModalTemplate;