import { fetchWithTimeout } from "@libs/fetch";
import {
  getSubscribePayload,
  getAddMySearchesPayload,
  getUpdateProfilePayload,
  getLoginPayload,
  getRecoverPasswordPayload,
  getResetPasswordPayload,
  getChangePrivacyPolicyPayload,
  getTmsWidgetPayload,
  getFavouriteJobsPayload,
  getAddFavouriteJobPayload,
  getTpmPayload
} from "@utils/payload";

const LOGIN_BASE_URL = `${window.jrConfigGlobal.baseUrl}login`;
const SUBSCRIBE_BASE_URL = `${window.jrConfigGlobal.baseUrl}jobseeker/create`;
const UPDATE_PROFILE_BASE_URL = `${window.jrConfigGlobal.baseUrl}updateprofile`;
const UPDATE_TPM_BASE_URL = `${window.jrConfigGlobal.baseUrl}tpm/update`;
const RESET_PASSWORD_BASE_URL = `${window.jrConfigGlobal.baseUrl}resetpassword`;
const ADD_MY_SEARCHES_BASE_URL = `${window.jrConfigGlobal.baseUrl}jobseeker/update`;
const PASSWORD_RECOVERY_BASE_URL = `${window.jrConfigGlobal.baseUrl}recoverpassword`;
const LOCATION_TYPEAHEAD_BASE_URL = `${window.jrConfigGlobal.baseUrl}public/api/location/autocomplete`;
const GOOGLE_ONE_TAP_SIGN_IN_USER_BASE_URL = `${window.jrConfigGlobal.baseUrl}public/api/onetap/v2/signin`;
const GOOGLE_ONE_TAP_CHECK_USER_BASE_URL = `${window.jrConfigGlobal.baseUrl}public/api/onetap/v2/checkuser`;
const SEND_CONFIRMATION_MAIL_BASE_URL = `${window.jrConfigGlobal.baseUrl}public/api/unconfirmed-jobseeker/send-confirmation`;
const CHANGE_PRIVACY_POLICY_BASE_URL = `${window.jrConfigGlobal.baseUrl}privacy-policy/change`;

export const subscribeJobseeker = (params: ISubscribe): Promise<Response> =>
  fetchWithTimeout(SUBSCRIBE_BASE_URL, {
    method: 'POST',
    body: getSubscribePayload(params),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });

export const addMySearches = (params: IAddMySearches): Promise<Response> =>
  fetchWithTimeout(ADD_MY_SEARCHES_BASE_URL, {
    method: 'POST',
    body: getAddMySearchesPayload(params.mySearches, params.email),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });

export const getLocations = (payload: string): Promise<Response> =>
  fetchWithTimeout(LOCATION_TYPEAHEAD_BASE_URL, {
    method: 'POST',
    body: payload,
    headers: {
      'Content-Type': 'application/json'
    }
  });

export const updateProfile = (params: IMyprofilePayload): Promise<Response> =>
  fetchWithTimeout(UPDATE_PROFILE_BASE_URL, {
    method: 'POST',
    body: getUpdateProfilePayload(params),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });

export const updateTpmConsent = (params: ITpmConsentPayload): Promise<Response> =>
  fetchWithTimeout(UPDATE_TPM_BASE_URL, {
    method: 'POST',
    body: getTpmPayload(params),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });


export const googleOneTapSignIn = (params: IGoogleOneTapSignIn): Promise<Response> => {
  const {
    idToken,
    page = 'SERP',
    statement,
    advertId,
    timeout
  } = params;

  return !idToken || !statement
    ? Promise.reject(
      new Error(`idToken or statement undefined [idToken=${idToken},statement=${statement}]`)
    )
    : fetchWithTimeout(`${GOOGLE_ONE_TAP_SIGN_IN_USER_BASE_URL}?page=${page}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        idToken,
        statement: {
          what: statement.what || undefined,
          where: statement.where || undefined,
          radius: statement.radius || undefined,
        },
        advertId,
      }),
      timeout
    });
}

export const googleOneTapCheckUser = (params: IGooleOneTapCheckUser): Promise<Response> => {
  const {
    idToken,
    timeout
  } = params;

  return !idToken
    ? Promise.reject(new Error(`idToken undefined [idToken=${idToken}]`))
    : fetchWithTimeout(`${GOOGLE_ONE_TAP_CHECK_USER_BASE_URL}?idToken=${idToken}`, { timeout });
}

export const login = (params: ILoginPayload): Promise<Response> =>
  fetchWithTimeout(LOGIN_BASE_URL, {
    method: 'POST',
    body: getLoginPayload(params),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });

export const recoverPassword = (email: string): Promise<Response> =>
  fetchWithTimeout(PASSWORD_RECOVERY_BASE_URL, {
    method: 'POST',
    body: getRecoverPasswordPayload(email),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });

export const resetPassword = (params: IResetPasswordPayload): Promise<Response> =>
  fetchWithTimeout(RESET_PASSWORD_BASE_URL, {
    method: 'POST',
    body: getResetPasswordPayload(params),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });

export const sendConfirmationMail = (): Promise<Response> =>
  fetchWithTimeout(SEND_CONFIRMATION_MAIL_BASE_URL, {
    method: 'GET'
  });

export const changePrivacyPolicy = (params: IPrivacyPolicyChangePayload): Promise<Response> =>
  fetchWithTimeout(`${CHANGE_PRIVACY_POLICY_BASE_URL}?${getChangePrivacyPolicyPayload(params)}`, {
    method: 'GET'
  });

export const getTmsWidget = (timeout: number, params: ITmsWidgetPayload): Promise<Response | null> => {
  if (window.jrConfigGlobal.tmsWidget) {
    return fetchWithTimeout(`${window.jrConfigGlobal.tmsWidget.url}?${getTmsWidgetPayload(params)}`, {
      method: 'GET',
      headers: {
        'Accept': 'text/html'
      },
      timeout
    })
  }

  return Promise.resolve(null);
};

export const addFavouriteJob = (params: IAddFavouriteJobPayload): Promise<Response | null> => {
  if (window.jrConfigGlobal.favouriteJobs) {
    return fetchWithTimeout(`${window.jrConfigGlobal.favouriteJobs.url}:${window.jrConfigGlobal.favouriteJobs.port}/favourite-jobs/`, {
      method: 'POST',
      body: getAddFavouriteJobPayload(params),
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  return Promise.resolve(null);
}

export const deleteFavouriteJob = (jobId: string): Promise<Response | null> => {
  if (window.jrConfigGlobal.favouriteJobs) {
    return fetchWithTimeout(`${window.jrConfigGlobal.favouriteJobs.url}:${window.jrConfigGlobal.favouriteJobs.port}/favourite-jobs/${jobId}`, {
      method: 'DELETE'
    })
  }

  return Promise.resolve(null);
}

export const checkJobseekerFavouriteJobs = (params: IGetFavouriteJobsPayload): Promise<Response | null> => {
  if (window.jrConfigGlobal.favouriteJobs) {
    return fetchWithTimeout(`${window.jrConfigGlobal.favouriteJobs.url}:${window.jrConfigGlobal.favouriteJobs.port}/favourite-jobs/?${getFavouriteJobsPayload(params)}`, {
      method: 'HEAD'
    })
  }

  return Promise.resolve(null);
}

export const getFavouriteJobs = (params: IGetFavouriteJobsPayload): Promise<Response | null> => {
  if (window.jrConfigGlobal.favouriteJobs) {
    return fetchWithTimeout(`${window.jrConfigGlobal.favouriteJobs.url}:${window.jrConfigGlobal.favouriteJobs.port}/favourite-jobs/?${getFavouriteJobsPayload(params)}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      },
    })
  }

  return Promise.resolve(null);
}
