import { html, TemplateResult } from "lit/html.js";

export const TopToolsLinkMenuDesktopComponent = (props: ITopToolsLinkMenu): TemplateResult => {

  const handleClick = (event: Event) => (<HTMLElement>event.currentTarget).classList.toggle("opened");

  const { jobseekerEmail, messages: { HeaderMyProfile, HeaderMySearches, HeaderFavouriteJobs, MySearchesLogoutButton } } = props;


  return html`
  <div class="smallset tooltip user" @click=${handleClick}>
    <div class="user-panel">
      <span>${jobseekerEmail}</span>
      <img src="/static/img/icons/ico_user.svg" class="user-panel-icon" />
    </div>
    <div class="tooltip-element tooltip-bottom">
      <div class="arrow-up"></div>
      <div class="tooltip-wrapper">
        <div class="tooltip-content">
          <div class="user-features">
            <a href="/myprofile" data-google-interstitial="false">${HeaderMyProfile}</a>
            <a href="/mysearches" data-google-interstitial="false">${HeaderMySearches}</a>
            <a href="/favouritejobs" data-google-interstitial="false">${HeaderFavouriteJobs}</a>
            <a title="${MySearchesLogoutButton}" data-google-interstitial="false"
              href="/logout">${MySearchesLogoutButton}</a>
          </div>
        </div>
      </div>
    </div>
  </div>`;
}