export class JtagService {
  public static trackSubscription = (subscriptionSource: string, emailDomain: string): void => {
    window.jtag('event', 'subscription', {
      source: subscriptionSource,
      email_domain: emailDomain
    });
  }

  public static trackConfirmation = (label: string): void => {
    window.jtag('event', 'confirmation', {
      event_label: label,
    });
  }

  public static trackSubscriptionProposal = (subscriptionSource: string): void => {
    window.jtag('event', 'subscription_proposal', {
      source: subscriptionSource
    });
  }

  public static trackCustomEvent = (eventName: string, params: IJtagParams = {}): void => {
    window.jtag('event', eventName, params);
  }

  public static trackPrivacyPolicyImpressionClient = (): void => {
    window.jtag('event', 'privacy_policy_impression_client');
  }

  public static trackPrivacyPolicyAcceptClient = (eventLabel: string): void => {
    window.jtag('event', 'privacy_policy_accept_client', {
      event_label: eventLabel,
    });
  }

  public static trackMarketingJobsPolicyClient = (eventLabel: string): void => {
    window.jtag('event', 'marketing_jobs_policy_client', {
      event_label: eventLabel,
    });
  }

  public static trackCustomEventImpression = (eventCategory: string): void => {
    window.jtag('event', 'abtest_impression', {
      event_category: eventCategory,
    });
  }

  public static trackCustomEventClick = (eventCategory: string): void => {
    window.jtag('event', 'abtest_click', {
      event_category: eventCategory,
    });
  }

  public static trackFavouriteJobs = (label: string, advertId: string): void => {
    window.jtag('event', 'favourite_jobs', {
      event_label: label,
      advertId
    });
  }

  public static trackMySearches = (label: string, statement: IStatement): void => {
    window.jtag('event', 'my_searches', {
      event_label: label,
      statement
    });
  }

  public static trackAdvertClick = (advert: IAdvert): void => {
    window.jtag('event', 'click_advert', {
      advert,
    });
  }

  public static getPvid = (): string => {
    return window.jtag('event', 'getPvid');
  }
}