const generateExpiryDate = (days?: number): string => {
  if (!days) return '';
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);

  return `; expires=${date.toUTCString()}`;
};

const generateDomain = (domain?: string): string => (domain ? `; domain=${domain}` : '');

const generateSameSite = (sameSite?: string): string => (sameSite ? `; samesite=${sameSite}` : '');

const generateSecure = (isSecure: boolean): string => (isSecure ? '; secure' : '');

export const createCookie = (name: string, value: string | number | boolean, options?: CookieOptions): void => {
  const { days, domain, sameSite, isSecure = false, path = '/' } = options || {};

  document.cookie = `${name}=${encodeURIComponent(value)}${generateDomain(domain)}${generateSameSite(
    sameSite
  )}${generateSecure(isSecure)}; path=${path}${generateExpiryDate(days)}`;
};

export const readCookie = (name: string): string | null => {
  const cookie = document.cookie.split(';').find((row) => row.trim().startsWith(`${name}=`));

  return cookie ? cookie.slice(cookie.indexOf('=') + 1) : null;
};

export const eraseCookie = (name: string): void => {
  createCookie(name, '', { days: -1 });
};
