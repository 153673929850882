import { jrLocalStorage, JrLocalStorageKeys } from "@utils/storage";
import { createCookie, readCookie, eraseCookie } from "@utils/cookie";
import { isUserComingFromSerp } from "./referrer-service";

export enum PolicyType {
  ON_LOAD = 'ON_LOAD',
  NO_POPUP = 'NO_POPUP',
  AFTER_LANDING = 'AFTER_LANDING'
}

export enum AdvertType {
  SPONSORED = 'SPONSORED',
  ORGANIC = 'ORGANIC',
  PROMOTED = 'PROMOTED'
}

export const subscriptionCookieInfo: SubscriptionCookieInfo = {
  COOKIE_NAME: 'spcnt',
  COOKIE_EXPIRES: 36500
}

export const interstitialCookieInfo: SubscriptionCookieInfo = {
  COOKIE_NAME: 'int_sp_block',
  COOKIE_EXPIRES: 0.05
}

export const isSubscriptionPopupVisibile = (
  cookieValue: number | null,
  subscription: ISubscription = window.jrConfigGlobal.subscription): boolean => {
  const { popupMaxCount, popupPolicyType } = subscription;

  switch (popupPolicyType) {
    case PolicyType.ON_LOAD:
      return cookieValue === null || cookieValue < popupMaxCount;
    case PolicyType.AFTER_LANDING:
      return cookieValue !== null && cookieValue < popupMaxCount;
    case PolicyType.NO_POPUP:
      return false;
    default:
      return false;
  }
}

export const manageSubscriptionPopupVisibilityInJPP = (advertType: string) => {

  if (advertType !== AdvertType.ORGANIC) {
    const cookiValue = readCookie(interstitialCookieInfo.COOKIE_NAME)
    if (cookiValue) {
      eraseCookie(interstitialCookieInfo.COOKIE_NAME)
    }
    return;
  }

  const [lastInterstitialDisplayedInMilliseconds = 0] = jrLocalStorage.getItem<number[]>(JrLocalStorageKeys.INTERSTITIAL_ADS_LAST_DISPLAYED) || [];
  const interstitialFrequencyCapInHour = 1;
  const timeBetweenNowAndLastInterstitialDisplayedInHours = Math.abs(Date.now() - lastInterstitialDisplayedInMilliseconds) / 36e5;
  const shouldDisplayInterstitial = timeBetweenNowAndLastInterstitialDisplayedInHours > interstitialFrequencyCapInHour;

  if (shouldDisplayInterstitial) {
    createCookie(
      interstitialCookieInfo.COOKIE_NAME,
      1,
      { days: interstitialCookieInfo.COOKIE_EXPIRES });
  }
};

export const canShowSubscriptionPopupInJPP = (
  interstitialAdsEnabled: boolean = window.jrConfigGlobal.feature.interstitialAdsEnabled
): boolean => {

  const cookiValue = readCookie(interstitialCookieInfo.COOKIE_NAME)

  if (
    !interstitialAdsEnabled ||
    !jrLocalStorage.getItem<number[]>(JrLocalStorageKeys.INTERSTITIAL_ADS_LAST_DISPLAYED) ||
    !isUserComingFromSerp(window.location.href)
  ) {
    if (cookiValue) {
      eraseCookie(interstitialCookieInfo.COOKIE_NAME)
    }
    return true;
  }

  if (cookiValue) {
    eraseCookie(interstitialCookieInfo.COOKIE_NAME)
    return false;
  }

  return true;

}