import { isUserComingFromSerp } from './referrer-service';

const getJPPWhat = (what: ISearchWhat): string =>
  isUserComingFromSerp(window.location.href) ? what.term : what.computed;

const getJPPWhere = (where: ISearchWhere): string =>
  isUserComingFromSerp(window.location.href) ? (where.rewritten || where.term) : where.computed;

const isJPPEmptySearch = (search: ISearch = window.jrConfigGlobal.search): boolean => {
  const { rewritten: rewrittenWhat, computed: computedWhat } = search.what;
  const { rewritten: rewrittenWhere, computed: computedWhere } = search.where;

  return isUserComingFromSerp(window.location.href)
    ? !rewrittenWhat && !rewrittenWhere
    : !computedWhat && !computedWhere;
}

export const isEmptySearch = (jrConfigGlobal: Readonly<JrConfigGlobal> = window.jrConfigGlobal): boolean => {
  const {
    search,
    page: { isJobPreviewPage }
  } = jrConfigGlobal;

  return isJobPreviewPage ? isJPPEmptySearch(search) : (!search.what.rewritten && !search.where.rewritten);
}

export const getWhat = (jrConfigGlobal: Readonly<JrConfigGlobal> = window.jrConfigGlobal): string => {
  const {
    search: {
      what
    },
    page: { isJobPreviewPage }
  } = jrConfigGlobal;

  return isJobPreviewPage ? getJPPWhat(what) : what.term;
}

export const getWhere = (jrConfigGlobal: Readonly<JrConfigGlobal> = window.jrConfigGlobal): string => {
  const {
    search: {
      where
    },
    page: {
      isJobPreviewPage,
      isHome
    }
  } = jrConfigGlobal;

  if (isHome && where.resolvedByIp) {
    return where.resolvedByIp;
  }

  return isJobPreviewPage ? getJPPWhere(where) : (where.rewritten || where.term);
}

export const getRadius = (jrConfigGlobal: Readonly<JrConfigGlobal> = window.jrConfigGlobal): string => {
  const {
    search: {
      radius: {
        autoexpanded,
        value
      }
    }
  } = jrConfigGlobal;

  return autoexpanded === 'true' ? 'auto' : value;
}