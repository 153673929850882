import jrEmailTypeaheadMaterialTemplate from '../../view/angular/common/modal/material/email-typeahead-material.tpl.html';
import {
  getEmailProviders,
  getDomain,
  hasDomainSeparator,
  getUser,
} from '../../ts/service/email-service.ts';

angular
  .module('jrAutocompleteEmail', ['jrConfig'])
  .value('getEmailProviders', getEmailProviders)
  .value('getDomain', getDomain)
  .value('hasDomainSeparator', hasDomainSeparator)
  .value('getUser', getUser)
  .filter('jrTypeaheadHighlight', [
    'hasDomainSeparator',
    'getDomain',
    function (hasDomainSeparator, getDomain) {
      function escapeRegexp(queryToEscape) {
        return queryToEscape.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
      }

      return function (matchItem, query) {
        const extractedQuery = !!query && hasDomainSeparator(query) ? getDomain(query) : undefined;

        return extractedQuery
          ? matchItem.replace(new RegExp(escapeRegexp(extractedQuery), 'gi'), '<strong>$&</strong>')
          : matchItem;
      };
    },
  ])
  .directive('jrEmailTypeahead', [
    'AutocompleteEmailConfig',
    'jrEmailConstants',
    'getUser',
    '$timeout',
    '$filter',
    'Messages',
    'getEmailProviders',
    function (
      AutocompleteEmailConfig,
      jrEmailConstants,
      getUser,
      $timeout,
      $filter,
      Messages,
      getEmailProviders
    ) {
      return {
        restrict: 'AE',
        replace: true,
        scope: {
          placeholder: '@',
          disabled: '=',
          jobseeker: '=',
          focus: '=',
          email: '=',
          invalid: '=',
          cssclasses: '@',
        },
        template: jrEmailTypeaheadMaterialTemplate,
        link(scope, elem) {
          scope.typeaheadFirstItem = '';
          scope.providersList = AutocompleteEmailConfig.providersList;
          scope.providersLimit = AutocompleteEmailConfig.providersLimit;
          scope.emailDomainMinLength = AutocompleteEmailConfig.emailDomainMinLength;
          scope.messages = Messages;

          scope.typeaheadOnSelect = function (item, email) {
            const result = [];
            result.push(getUser(email));
            result.push(jrEmailConstants.domainSeparator);
            result.push(item.value);
            scope.jobseeker.email = result.join('');
            scope.typeaheadFirstItem = '';
          };

          scope.getProviderList = function (val) {
            const filteredProvidersList = $filter('limitTo')(
              getEmailProviders(val),
              AutocompleteEmailConfig.providersLimit
            );

            if (filteredProvidersList.length > 0) {
              scope.typeaheadFirstItem = val.replace(/@.*/g, filteredProvidersList[0].label);
            } else {
              scope.typeaheadFirstItem = '';
            }

            return filteredProvidersList;
          };

          scope.$watch('focus', (value) => {
            if (value) {
              $timeout(() => {
                elem[0].querySelector('.jr-input').focus();
              });
            }
          });
        },
      };
    },
  ]);
