import { JtagService } from '../../ts/service/jtag-service.ts';
import { getDomain } from '../../ts/service/email-service.ts';
import { subscribeJobseeker, updateTpmConsent } from '../../ts/service/xhr-service.ts';
import { pushEvent } from '../../ts/service/gtm-service.ts';
import {
  isSubscriptionPopupVisibile,
  subscriptionCookieInfo,
  PolicyType,
} from '../../ts/service/subscription-popup-service.ts';
import { readCookie } from '../../ts/utils/cookie.ts';
import { searchFormatter, searchUrlFormatter } from '../../ts/utils/formatters.ts';
import {
  renderTopToolsLinkMenuDesktopComponent,
  renderTopToolsLinkMenuMobileComponent,
  renderMySearchesComponent,
} from '../renderComponentUtils';
import { loadWelcomeOrConfirmationReminderModal } from '../../ts/component/modal/welcomeOrConfirmationReminder/loader.ts';
import { EventConstants, once, trigger } from '../../ts/service/event-service.ts';
import { isValidDomain } from '../../ts/utils/index.ts';

angular
  .module('jrProposal', ['jrConfig', 'jrUtil', 'jrCommonService'])
  .value('readCookie', readCookie)
  .value('isSubscriptionPopupVisibile', isSubscriptionPopupVisibile)
  .value('subscriptionCookieInfo', subscriptionCookieInfo)
  .value('PolicyType', PolicyType)
  .value('searchFormatter', searchFormatter)
  .value('searchUrlFormatter', searchUrlFormatter)
  .value('renderTopToolsLinkMenuDesktopComponent', renderTopToolsLinkMenuDesktopComponent)
  .value('renderTopToolsLinkMenuMobileComponent', renderTopToolsLinkMenuMobileComponent)
  .value('renderMySearchesComponent', renderMySearchesComponent)
  .value('isValidDomain', isValidDomain)
  .controller('SerpSubscriptionProposalCtrl', [
    'Messages',
    'SearchConfig',
    'JobseekerConfig',
    'AutocompleteEmailConfig',
    'SearchFormService',
    'Banners',
    '$scope',
    '$element',
    'isValidDomain',
    'isSubscriptionPopupVisibile',
    'searchFormatter',
    function (
      Messages,
      SearchConfig,
      JobseekerConfig,
      AutocompleteEmailConfig,
      SearchFormService,
      Banners,
      $scope,
      $element,
      isValidDomain,
      isSubscriptionPopupVisibile,
      searchFormatter
    ) {
      $scope.messages = Messages;
      $scope.autocompleteEnabled = AutocompleteEmailConfig.enabled;
      $scope.state = JobseekerConfig.email !== undefined ? 'confirm-activation' : 'proposal';
      $scope.jobseeker = {
        email: JobseekerConfig.email,
      };
      $scope.marketingConsent = false;
      $scope.subBoxBtfEnabled = jrConfigGlobal.feature.subBoxBtfEnabled;
      $scope.hasBeenSubmitted = false;
      $scope.loading = false;
      $scope.errorState = false;
      $scope.subscriptionDaemon = '';
      $scope.forms = {};
      $scope.search = SearchConfig;
      $scope.isEmptySearch = SearchConfig.isEmptySearch;
      $scope.npsBannerEnabled = Banners.npsBannerEnabled;
      $scope.captchaUserResponse = '';
      $scope.captchaEnabled = SearchConfig.isSubscriptionCaptchaEnabled;
      $scope.captchaTest = window.jrConfigGlobal.captcha.isTest;
      $scope.domainError = false;
      $scope.tpmConsentBannerShow =
        window.jrConfigGlobal.feature.gdprModalConsentThirdPartyMarketingEnabled;
      $scope.tpmConsentBannerInverted =
        window.jrConfigGlobal.feature.gdprModalConsentThirdPartyMarketingInverted;
      $scope.tpmConsentShow =
        window.jrConfigGlobal.feature.gdprThirdPartyMarketingSubscriptionEnabled;
      $scope.banner = document.getElementById('tpm-banner');

      $scope.tpmConsent = async function (value) {
        await updateTpmConsent({
          tpmaccepted: value,
        });
        $scope.tpmConsentBannerShow = false;
        $scope.banner.classList.remove('show');
        $scope.$apply();
      };
      $scope.bannerShown = async function ($scope) {
        await updateTpmConsent({
          tpmaccepted: null,
        });
      };

      once(EventConstants.subscriptionSent, function (event) {
        $scope.jobseeker.email = event.detail.email;
        $scope.state = 'confirm-activation';
        $scope.$digest();
      });

      $scope.reset = function () {
        $scope.jobseeker = {
          email: undefined,
        };
        $scope.hasBeenSubmitted = false;
        $scope.loading = false;
        $scope.errorState = false;
        $scope.domainError = false;
        $scope.state = 'proposal';
      };

      $scope.searchLabel = searchFormatter(
        {
          what: SearchFormService.search.what,
          where: SearchFormService.search.where,
          rewrittenWhere: SearchFormService.search.rewrittenWhere,
          distance: SearchFormService.search.distance,
        },
        true
      );

      const cookieValue = readCookie(subscriptionCookieInfo.COOKIE_NAME);
      $scope.showProposalModal = isSubscriptionPopupVisibile(cookieValue);

      const handleAfterSubscription = (result) => {
        $scope.loading = false;
        const jobseekerCreated = result.status >= 200 && result.status < 300;
        pushEvent({
          event: 'jobseekerCreate',
          created: jobseekerCreated,
          subscriberUid: result.headers.get('subscriberUid'),
          subscriptionConversionValue: parseFloat(
            result.headers.get('subscriptionConversionValue') || '0'
          ),
        });

        if (!jobseekerCreated) {
          $scope.state = 'proposal';
          $scope.errorState = true;
          if ($scope.captchaTest === false) {
            console.log('creating jobseeker with captcha', $scope.captchaTest);
            if (SearchConfig.isSubscriptionCaptchaEnabled && $scope.proposalBoxCaptcha > -1) {
              grecaptcha.reset($scope.proposalBoxCaptcha);
            }
            $scope.$apply();
            return;
          }
          console.log('creating jobseeker without captcha', $scope.captchaTest);

          $scope.$apply();
          return;
        }

        $scope.state = 'confirm-activation';

        if ($element.hasClass('proposal-box')) {
          if ($element.hasClass('fixed')) {
            JtagService.trackSubscription(
              'rightside_bottom',
              getDomain($scope.jobseeker.email) || ''
            );
          } else {
            JtagService.trackSubscription('rightside_top', getDomain($scope.jobseeker.email) || '');
          }
        }

        if (jrConfigGlobal.isMobile) {
          if (jrConfigGlobal.feature.subBoxBtfEnabled) {
            JtagService.trackSubscription('box_btf', getDomain($scope.jobseeker.email) || '');
          }
        }

        $scope.$apply();
      };

      const registerJobseeker = async () => {
        const response = await subscribeJobseeker({
          email: $scope.jobseeker.email,
          marketingConsent: $scope.forms.serpProposal.marketingConsent
            ? $scope.forms.serpProposal.marketingConsent.$viewValue
            : '',
          where: SearchFormService.search.where,
          captchaResponse: $scope.captchaUserResponse,
        });
        handleAfterSubscription(response);
      };

      const handleCaptchaError = () => {
        $scope.loading = false;
        $scope.hasBeenSubmitted = false;
        grecaptcha.reset($scope.proposalBoxCaptcha);
      };

      const handleCaptchaCallback = async (response) => {
        if (response) {
          $scope.captchaUserResponse = response;
          await registerJobseeker();
        } else {
          handleCaptchaError();
        }
      };

      const loadGoogleCaptcha = () => {
        const interval = setInterval(() => {
          if (window.grecaptcha && $scope.captchaTest === false) {
            grecaptcha.ready(() => {
              $scope.proposalBoxCaptcha = grecaptcha.render('proposal-box-captcha-container', {
                sitekey: jrConfigGlobal.captcha.siteKey,
                size: 'invisible',
                badge: 'inline',
                callback: handleCaptchaCallback,
                'error-callback': handleCaptchaError,
                'expired-callback': handleCaptchaError,
              });
            });
            clearInterval(interval);
          }
        }, 100);
      };

      if (SearchConfig.isSubscriptionCaptchaEnabled && $scope.captchaTest === false) {
        $element.ready(loadGoogleCaptcha);
      }

      $scope.submit = async (e) => {
        $scope.loading = true;
        $scope.hasBeenSubmitted = true;

        if (
          !isValidDomain($scope.forms.singleConfirmationProposal.email.$viewValue) &&
          jrConfigGlobal.feature.emailDomainValidationEnabled
        ) {
          $scope.loading = false;
          $scope.hasBeenSubmitted = false;
          $scope.domainError = true;
          return;
        }
        if (!$scope.forms.serpProposal.$valid) {
          $scope.loading = false;
          $scope.hasBeenSubmitted = false;
          $scope.errorState = true;
        } else if (
          SearchConfig.isSubscriptionCaptchaEnabled &&
          $scope.proposalBoxCaptcha !== -1 &&
          $scope.captchaTest === false
        ) {
          grecaptcha.ready(() => {
            grecaptcha.execute($scope.proposalBoxCaptcha);
          });
        } else {
          await registerJobseeker();
        }
        e.preventDefault();
      };
    },
  ])
  .controller('SingleConfirmationProposalCtrl', [
    'Messages',
    'SearchConfig',
    'JobseekerConfig',
    'AutocompleteEmailConfig',
    'SearchFormService',
    'Banners',
    '$scope',
    '$element',
    'isValidDomain',
    'isSubscriptionPopupVisibile',
    'searchFormatter',
    'searchUrlFormatter',
    'renderTopToolsLinkMenuDesktopComponent',
    'renderTopToolsLinkMenuMobileComponent',
    'renderMySearchesComponent',
    function (
      Messages,
      SearchConfig,
      JobseekerConfig,
      AutocompleteEmailConfig,
      SearchFormService,
      Banners,
      $scope,
      $element,
      isValidDomain,
      isSubscriptionPopupVisibile,
      searchFormatter,
      searchUrlFormatter,
      renderTopToolsLinkMenuDesktopComponent,
      renderTopToolsLinkMenuMobileComponent,
      renderMySearchesComponent
    ) {
      $scope.messages = Messages;
      $scope.autocompleteEnabled = AutocompleteEmailConfig.enabled;
      $scope.jobseeker = {
        email: JobseekerConfig.email,
      };
      $scope.marketingConsent = false;
      $scope.subBoxBtfEnabled = jrConfigGlobal.feature.subBoxBtfEnabled;
      $scope.hasBeenSubmitted = false;
      $scope.loading = false;
      $scope.errorState = false;
      $scope.forms = {};
      $scope.search = SearchConfig;
      $scope.isEmptySearch = SearchConfig.isEmptySearch;
      $scope.npsBannerEnabled = Banners.npsBannerEnabled;
      $scope.captchaUserResponse = '';
      $scope.captchaEnabled = SearchConfig.isSubscriptionCaptchaEnabled;
      $scope.captchaTest = window.jrConfigGlobal.captcha.isTest;
      $scope.state = '';
      $scope.domainError = false;
      $scope.searchLabel = searchFormatter(
        {
          what: SearchFormService.search.what,
          where: SearchFormService.search.where,
          rewrittenWhere: SearchFormService.search.rewrittenWhere,
          distance: SearchFormService.search.distance,
        },
        true
      );
      $scope.marketingConsent = false;
      const cookieValue = readCookie(subscriptionCookieInfo.COOKIE_NAME);
      $scope.showProposalModal = isSubscriptionPopupVisibile(cookieValue);
      $scope.tpmConsentShow =
        window.jrConfigGlobal.feature.gdprThirdPartyMarketingSubscriptionEnabled;
      once(EventConstants.singleOptInSubscriptionConfirmed, function () {
        $scope.state = 'jobseekerConfirmed';
        $scope.$digest();
      });

      const renderMySearches = () => {
        const mySearches = [
          {
            search: {
              what: SearchFormService.search.what,
              where: SearchFormService.search.where,
            },
            url: searchUrlFormatter({
              what: SearchFormService.search.what,
              where: SearchFormService.search.where,
            }),
          },
        ];
        renderMySearchesComponent({
          mySearches,
          messages: jrConfigGlobal.messages,
        });
      };

      const renderTopToolsLinkMenu = () => {
        if (jrConfigGlobal.isDesktop) {
          renderTopToolsLinkMenuDesktopComponent({
            jobseekerEmail: $scope.jobseeker.email,
            messages: jrConfigGlobal.messages,
          });
        } else {
          renderTopToolsLinkMenuMobileComponent({
            messages: jrConfigGlobal.messages,
          });
        }
      };

      const hideGoogleOneTap = () => {
        if (window.google && window.google.accounts) window.google.accounts.id.cancel();
      };

      const hideNotificationBox = () => {
        if (jrConfigGlobal.feature.notificationAlertBoxEnabled) {
          const notificationBox = document.querySelector('.js-notification-alert');
          if (notificationBox) {
            notificationBox.classList.add('hide');
          }
        }
      };

      const handleSingleOptinNoRefreshFlow = () => {
        trigger(EventConstants.singleOptInSubscriptionConfirmed, {
          email: $scope.jobseeker.email,
        });
        hideNotificationBox();
        hideGoogleOneTap();
        renderTopToolsLinkMenu();
        renderMySearches();
      };

      const handleAfterSubscription = (result) => {
        $scope.loading = false;
        const jobseekerCreated = result.status >= 200 && result.status < 300;
        pushEvent({
          event: 'jobseekerCreate',
          created: jobseekerCreated,
          subscriberUid: result.headers.get('subscriberUid'),
          subscriptionConversionValue: parseFloat(
            result.headers.get('subscriptionConversionValue') || '0'
          ),
        });

        if (!jobseekerCreated) {
          $scope.errorState = true;
          if ($scope.captchaTest === false) {
            if (SearchConfig.isSubscriptionCaptchaEnabled && $scope.proposalBoxCaptcha > -1) {
              grecaptcha.reset($scope.proposalBoxCaptcha);
            }
          }
          $scope.$apply();
          return;
        }

        if ($element.hasClass('proposal-box')) {
          if ($element.hasClass('fixed')) {
            JtagService.trackSubscription(
              'rightside_bottom',
              getDomain($scope.jobseeker.email) || ''
            );
          } else {
            JtagService.trackSubscription('rightside_top', getDomain($scope.jobseeker.email) || '');
          }
        }

        if (jrConfigGlobal.isMobile) {
          if (jrConfigGlobal.feature.subBoxBtfEnabled) {
            JtagService.trackSubscription('box_btf', getDomain($scope.jobseeker.email) || '');
          }
        }

        JtagService.trackConfirmation('single_opt_in');

        $scope.$apply();

        if (jrConfigGlobal.feature.singleOptInNoRefreshEnabled) {
          handleSingleOptinNoRefreshFlow();
        }

        if (jrConfigGlobal.feature.singleOptInWelcomePopupEnabled) {
          loadWelcomeOrConfirmationReminderModal({
            emailAddress: $scope.jobseeker.email,
            source: 'welcome',
            shouldReload: true,
          });
          return;
        }

        if (!jrConfigGlobal.feature.singleOptInNoRefreshEnabled) {
          window.location.reload();
        }
      };

      const registerJobseeker = async () => {
        const response = await subscribeJobseeker({
          email: $scope.jobseeker.email,
          marketingConsent: $scope.forms.singleConfirmationProposal.marketingConsent
            ? $scope.forms.singleConfirmationProposal.marketingConsent.$viewValue
            : '',
          what: SearchFormService.search.what,
          where: SearchFormService.search.where,
          captchaResponse: $scope.captchaUserResponse,
        });
        handleAfterSubscription(response);
      };

      const handleCaptchaError = () => {
        $scope.loading = false;
        $scope.hasBeenSubmitted = false;
        grecaptcha.reset($scope.proposalBoxCaptcha);
      };

      const handleCaptchaCallback = async (response) => {
        if (response) {
          $scope.captchaUserResponse = response;
          await registerJobseeker();
        } else {
          handleCaptchaError();
        }
      };

      const loadGoogleCaptcha = () => {
        const interval = setInterval(() => {
          if (window.grecaptcha && $scope.captchaTest === false) {
            grecaptcha.ready(() => {
              $scope.proposalBoxCaptcha = grecaptcha.render('proposal-box-captcha-container', {
                sitekey: jrConfigGlobal.captcha.siteKey,
                size: 'invisible',
                badge: 'inline',
                callback: handleCaptchaCallback,
                'error-callback': handleCaptchaError,
                'expired-callback': handleCaptchaError,
              });
            });
            clearInterval(interval);
          }
        }, 100);
      };

      if (SearchConfig.isSubscriptionCaptchaEnabled && $scope.captchaTest === false) {
        $element.ready(loadGoogleCaptcha);
      }

      $scope.submit = async (e) => {
        e.preventDefault();

        $scope.loading = true;
        $scope.hasBeenSubmitted = true;
        if (
          !isValidDomain($scope.forms.singleConfirmationProposal.email.$viewValue) &&
          jrConfigGlobal.feature.emailDomainValidationEnabled
        ) {
          $scope.domainError = true;
          $scope.loading = false;
          $scope.hasBeenSubmitted = false;
          return;
        }
        if (!$scope.forms.singleConfirmationProposal.$valid) {
          $scope.loading = false;
          $scope.hasBeenSubmitted = false;
          $scope.errorState = true;
        } else if (
          SearchConfig.isSubscriptionCaptchaEnabled &&
          $scope.proposalBoxCaptcha !== -1 &&
          $scope.captchaTest === false
        ) {
          grecaptcha.ready(() => {
            grecaptcha.execute($scope.proposalBoxCaptcha);
          });
        } else {
          await registerJobseeker();
        }

        e.preventDefault();
      };
    },
  ]);
