import type { TemplateResult } from 'lit/html.js';
import JrModal from '@libs/modal/index';
import { JtagService } from '@services/jtag-service';
import { findProvider } from '@services/link-to-inbox-service';
import { sendConfirmationMail } from '@services/xhr-service';
import { activatePushnami } from '@services/notification-service';
import { createCookie, readCookie } from '@utils/cookie';
import WelcomeOrConfirmationReminderTemplate from './template';

export default class WelcomeOrConfirmationReminderModal extends JrModal {
  private linkToInboxProviderUrl: string;

  constructor(
    readonly customModalClass: string,
    readonly wrapperClass: string,
    readonly emailAddress: string,
    readonly source: 'welcome' | 'google_one_tap' | 'confirmation_reminder',
    readonly shouldReload: boolean,
  ) {
    super(customModalClass, wrapperClass);
    this.linkToInboxProviderUrl = WelcomeOrConfirmationReminderModal.getLinkToInboxProviderUrl(emailAddress)
  }

  handleAfterClose = async (): Promise<void> => {
    activatePushnami();
    switch (this.source) {
      case 'welcome':
        this.welcomeAfterCloseCallback();
        break;
      case 'confirmation_reminder':
        WelcomeOrConfirmationReminderModal.confirmationReminderAfterCloseCallback()
        break;
      default:
        break;
    }
  }

  getContent = async (): Promise<TemplateResult> => WelcomeOrConfirmationReminderTemplate({
    close: this.close,
    email: this.emailAddress,
    messages: window.jrConfigGlobal.messages,
    linkToInboxProviderUrl: this.linkToInboxProviderUrl,
    source: this.source
  });

  openedCallback = (): void => {
    if (this.source === 'confirmation_reminder') {
      WelcomeOrConfirmationReminderModal.confirmationReminderOpenedCallback();
    }
  }

  private static getLinkToInboxProviderUrl = (email: string): string => {
    const providersList = findProvider(email);

    return providersList.length ? providersList[0].url : ''
  }

  private static handleSingleOptinRefreshFlow = () => {
    setTimeout(() => {
      window.location.reload();
    });
  }

  private welcomeAfterCloseCallback = () => {
    if (this.shouldReload && !window.jrConfigGlobal.feature.singleOptInNoRefreshEnabled) {
      WelcomeOrConfirmationReminderModal.handleSingleOptinRefreshFlow();
    }
  }

  private static confirmationReminderOpenedCallback = () => {
    const CONFIRMATION_REMINDER_COOKIE_NAME = 'confirmReminderModalCount';
    const confirmationReminderCoookieValue = readCookie(CONFIRMATION_REMINDER_COOKIE_NAME);

    createCookie(CONFIRMATION_REMINDER_COOKIE_NAME, !confirmationReminderCoookieValue ? 1 : parseInt(confirmationReminderCoookieValue, 10) + 1, { days: 30 });
    JtagService.trackCustomEvent('confirm_reminder_modal_impression_client');
  }

  private static confirmationReminderAfterCloseCallback = () => {
    sendConfirmationMail();
    JtagService.trackCustomEvent('confirm_reminder_modal_click_button');
  }

}