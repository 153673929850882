import { html, TemplateResult } from "lit/html.js";
import CloseButtonTemplate from '@libs/modal/closeButton';
import { WelcomeModalTemplate } from "@components/commonTemplate";

const WelcomeOrConfirmationReminderTemplate = (props: IWelcomeOrConfirmationReminderComponent): TemplateResult => {
  const {
    messages,
    linkToInboxProviderUrl,
    email,
    source,
    close
  } = props;

  const welcomeTemplateProps: IWelcomeModalTemplateProps = {
    messages,
    close,
    linkToInboxProviderUrl,
    email,
    source
  };

  return html`
      <div class="js-modal-skeleton">
        <div class="${source === 'google_one_tap' ? 'go-to-mail' : 'proposal'}">
          ${CloseButtonTemplate(close)}
          ${WelcomeModalTemplate(welcomeTemplateProps)}
        </div>
      </div>
  `;
}

export default WelcomeOrConfirmationReminderTemplate;