type EmailAddressProvider = IAutocompleteEmailProvider | LinkToInboxProvider;

type ProviderType = 'autocomplete' | 'inboxlink';

type ProviderReturnType<T> = T extends 'autocomplete'
  ? IAutocompleteEmailProvider[]
  : T extends 'inboxlink'
  ? LinkToInboxProvider[]
  : never;

const checkHasDomainSeparator = (email: string): boolean => email ? email.search('@') !== -1 : false;

const extractEmailDomain = (email: string): string | undefined => checkHasDomainSeparator(email)
  ? email.split('@').pop()
  : undefined;

const extractEmailUser = (email: string): string | undefined => checkHasDomainSeparator(email)
  ? email.split('@').shift()
  : undefined;

const contains = (domain: string) => (provider: EmailAddressProvider) => new RegExp(`^${domain}`).test(provider.pattern.replace(/@/g, ''));

const getListFor = <T extends ProviderType>(type: T, global: Window = window): ProviderReturnType<T> => {
  switch (type) {
    case 'autocomplete':
      return global.jrConfigGlobal.autocompleteEmail.providersList as ProviderReturnType<T>;
    case 'inboxlink':
      return global.jrConfigGlobal.linkToInbox.providersList as ProviderReturnType<T>;
    default:
      throw new Error(`Unexpected emailProviderType ${type}`);
  }
};

export const getEmailProviders = (email: string) => {
  const emailDomain = extractEmailDomain(email);

  return emailDomain
    ? getListFor('autocomplete').filter(contains(emailDomain))
    : [];
};

export const getLinkToInboxProviders = (email: string): LinkToInboxProvider[] => {
  const emailDomain = extractEmailDomain(email);

  return emailDomain
    ? getListFor('inboxlink').filter(contains(emailDomain))
    : [];
};

export const getDomain = (email: string): string | undefined => extractEmailDomain(email);

export const getUser = (email: string): string | undefined => extractEmailUser(email);

export const hasDomainSeparator = (email: string): boolean => checkHasDomainSeparator(email);
