// eslint-disable-next-line import/extensions
import { render } from 'lit/html.js';
import { TopToolsLinkMenuDesktopComponent } from '../ts/component/topToolsLinkMenuDesktop/index.ts';
import { TopToolsLinkMenuMobileComponent } from '../ts/component/topToolsLinkMenuMobile/index.ts';
import { MySearchesComponent } from '../ts/component/mySearches/index.ts';

export const renderTopToolsLinkMenuDesktopComponent = (props) =>
  render(
    TopToolsLinkMenuDesktopComponent(props),
    document.querySelector('.jobseeker-account-wrapper')
  );

export const renderTopToolsLinkMenuMobileComponent = (props) =>
  render(
    TopToolsLinkMenuMobileComponent(props),
    document.querySelector('.jobseeker-account-wrapper')
  );

export const renderMySearchesComponent = (props) =>
  render(MySearchesComponent(props), document.querySelector('.js-my-searches-wrapper'));
