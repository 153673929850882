import jrLocationTypeaheadMaterialTemplate from '../../../view/angular/common/modal/material/location-typeahead-material.tpl.html';
import { JtagService } from '../../../ts/service/jtag-service.ts';

angular
  .module('jrCommonDirective', ['jrConfig'])
  .directive('jrFocusMe', jrFocusMe)
  .directive('tooltipToggle', tooltipToggle)
  .directive('jrHeaderFixed', jrHeaderFixed)
  .directive('jrLocationTypeahead', jrLocationTypeahead);

jrFocusMe.$inject = ['$timeout', '$parse'];
tooltipToggle.$inject = ['$document', '$window'];
jrHeaderFixed.$inject = ['$window'];
jrLocationTypeahead.$inject = ['SearchConfig', '$http'];

function jrFocusMe($timeout, $parse) {
  return {
    link(scope, element, attrs) {
      const model = $parse(attrs.jrFocusMe);
      const unwatch = scope.$watch(model, (value) => {
        if (value) {
          $timeout(() => {
            element[0].focus();
            unwatch();
          });
        }
      });
    },
  };
}

function tooltipToggle($document, $window) {
  let openElement = null;
  let closeMenu = angular.noop;
  const openedClass = 'opened';
  return {
    restrict: 'CA',
    link(scope, element, attrs) {
      const tooltip = element.parent();

      tooltip.bind('click', () => {
        closeMenu();
      });
      element.bind('click', (event) => {
        const elementWasOpen = element === openElement;

        event.preventDefault();
        event.stopPropagation();

        if (openElement) {
          closeMenu();
        }

        if (!elementWasOpen && !element.hasClass('disabled') && !element.prop('disabled')) {
          tooltip.addClass(openedClass);
          openElement = element;
          closeMenu = function (e) {
            if (e) {
              e.preventDefault();
              e.stopPropagation();
            }
            $document.unbind('click', closeMenu);
            tooltip.removeClass(openedClass);
            closeMenu = angular.noop;
            openElement = null;
          };
          $document.bind('click', closeMenu);
        }
      });

      angular.element($window).bind('scroll', () => {
        const headerBottom = document.getElementsByClassName('js-header-bottom');
        const fixedHeader =
          headerBottom.length > 0 ? headerBottom[0].className.indexOf('fixed') > 0 : false;
        if (tooltip.hasClass(openedClass) && fixedHeader) {
          tooltip.removeClass(openedClass);
          openElement = null;
        }
      });
    },
  };
}

function jrHeaderFixed($window) {
  return {
    restrict: 'A',
    link(scope, element, attrs) {
      if (screen.width < 1024) {
        setMobileSearchFormFixed();
      } else {
        setHeaderBottomFixed();
      }

      angular.element($window).bind('orientationchange', (e) => {
        if (screen.width < 1024) {
          setMobileSearchFormFixed();
        } else {
          setHeaderBottomFixed();
        }
      });

      function setHeaderBottomFixed() {
        const headerBottom = document.getElementsByClassName('js-header-bottom')[0];
        const leaderboardWrapper = document.getElementsByClassName('leaderboard-header-wrapper');
        const mainContainerRow = document.getElementsByClassName('main-container-row')[0];

        angular.element($window).bind('scroll', () => {
          const headerTopHeight = document.getElementsByClassName('js-header-top')[0].offsetHeight;
          let spaceAboveHeader = headerTopHeight;
          const scrollSize = window.pageYOffset || document.documentElement.scrollTop;

          if (leaderboardWrapper.length > 0) {
            spaceAboveHeader += leaderboardWrapper[0].offsetHeight;
          }
          if (scrollSize >= spaceAboveHeader) {
            if (!headerBottom.classList.contains('fixed')) {
              headerBottom.classList.add('fixed');
            }
            if (!mainContainerRow.classList.contains('search-form-fixed')) {
              mainContainerRow.classList.add('search-form-fixed');
            }
          } else {
            if (headerBottom.classList.contains('fixed')) {
              headerBottom.classList.remove('fixed');
            }
            if (mainContainerRow.classList.contains('search-form-fixed')) {
              mainContainerRow.classList.remove('search-form-fixed');
            }
          }
        });
      }

      function setMobileSearchFormFixed() {
        const header = document.getElementById('header');
        const headerWrapper = document.getElementsByClassName('js-header-wrapper')[0];
        let headerHeight = header.getBoundingClientRect().height;
        const mainContainerRow = document.getElementsByClassName('main-container-row')[0];
        const leaderboardWrapper = document.getElementsByClassName('leaderboard-header-wrapper')[0];

        angular.element($window).bind('scroll', () => {
          if (leaderboardWrapper) {
            headerHeight = header.offsetHeight + leaderboardWrapper.offsetHeight;
          }

          const scrollSize = window.pageYOffset || document.documentElement.scrollTop;

          if (scrollSize >= headerHeight) {
            if (!element.hasClass('fixed')) {
              element.addClass('fixed');
            }
            if (!mainContainerRow.classList.contains('search-form-fixed')) {
              mainContainerRow.classList.add('search-form-fixed');
            }
          } else {
            if (element.hasClass('fixed')) {
              element.removeClass('fixed');
            }
            if (headerWrapper.classList.contains('fixed')) {
              headerWrapper.classList.remove('fixed');
            }
            if (mainContainerRow.classList.contains('search-form-fixed')) {
              mainContainerRow.classList.remove('search-form-fixed');
            }
          }
        });
      }
    },
  };
}

function jrLocationTypeahead(SearchConfig, $http) {
  return {
    restrict: 'AE',
    replace: true,
    template: jrLocationTypeaheadMaterialTemplate,
    link(scope, elem, attr) {
      scope.config = attr;
      scope.whereClearButtonEnabled = !!scope.whereClearButtonEnabled || !!SearchConfig.whereByIp;
      scope.typeaheadFirstItem = '';
      if (!scope.search) {
        scope.search = { where: attr.value };
      }

      scope.clearInput = function () {
        scope.isEmptyWhere = true;
        scope.search.where = null;
        scope.whereClearButtonEnabled = false;

        JtagService.trackCustomEvent('subscription.modal.clean.where.by.ip.input');
      };

      scope.getLocation = function (val, size) {
        return $http
          .post('/public/api/location/autocomplete', {
            prefix: val.toLowerCase(),
            size,
          })
          .then((response) => {
            scope.typeaheadFirstItem =
              response.data.length > 0 ? response.data[0].canonicalName : '';

            return response.data.map((item) => item.canonicalName);
          });
      };
    },
  };
}
