export const isUserComingFromSerp = (pageUrl: string) => {
  const { referrer } = document;

  return /jobrapido.com/i.test(referrer) && referrer !== pageUrl;
}

type QueryStringParamsType = { [key: string]: string };

export const getQueryStringParams = (query: string): QueryStringParamsType =>
  query
    ? (/^[?#]/.test(query) ? query.slice(1) : query).split('&').reduce((params: QueryStringParamsType, param: string): QueryStringParamsType => {
      const [key, value] = param.split('=');
      const result: QueryStringParamsType = params;
      result[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
      return result;
    }, {})
    : {};

export const getQueryParamFromUrl = (param: string, url: string): string => {
  const reg = new RegExp(`[?&]${param}=([^&#]*)`, 'i');
  const queryString = reg.exec(url);

  return queryString ? queryString[1] : '';
};