export enum EventConstants {
  inlineProposalActivated = 'inlineProposal:activated',
  inlineProposalDeactivated = 'inlineProposal:deactivated',
  subscriptionProposal = 'subscription:proposal',
  subscriptionSent = 'subscription:sent',
  singleOptInSubscriptionConfirmed = 'singleOptInSubscriptionConfirmed',
  subscriptionSentWhenApplyShowMoreFlowIsEnabled =
  'subscriptionSentWhenApplyShowMoreFlowIsEnabled',
}

export const trigger = (eventName: string, data?: Record<string, unknown>) => {
  const event = new CustomEvent(eventName, { detail: data });
  document.dispatchEvent(event);
}

export const on = (eventName: string, listener: EventListener) => {
  document.addEventListener(eventName, listener);
}

export const off = (eventName: string, listener: EventListener) => {
  document.removeEventListener(eventName, listener);
}

export const once = (eventType: string, listener: EventListener): void => {
  const handleEventOnce = (event: Event) => {
    listener(event);
    off(eventType, handleEventOnce);
  };

  on(eventType, handleEventOnce);
}