import { isUserComingFromSerp } from '../../../ts/service/referrer-service.ts';

angular
  .module('jrCommonService', ['jrConfig'])
  .value('isUserComingFromSerpMethod', isUserComingFromSerp)
  .factory('MySearchesService', MySearchesService)
  .factory('MySearchesResource', MySearchesResource)
  .factory('SearchFormService', SearchFormService)
  .factory('SearchOptionsService', SearchOptionsService);

MySearchesService.$inject = ['MySearchesConfig', 'SearchOptionsService'];
MySearchesResource.$inject = ['$http', 'jrGlobalConfig'];
SearchFormService.$inject = [
  'SearchConfig',
  'SearchOptionsService',
  'PagesConfig',
  'isUserComingFromSerpMethod',
];
SearchOptionsService.$inject = ['SearchFormOptionsConfig', 'SearchDistanceConfig', '$filter'];

function MySearchesService(MySearchesConfig, SearchOptionsService) {
  const statements = [];
  const decorateStatementWithDistance = function (statement) {
    return angular.extend(statement, {
      distance: SearchOptionsService.findDistance(statement.radiusValue),
      distances: SearchOptionsService.distances,
    });
  };
  const addStatement = function (statement) {
    if (statement === undefined) {
      throw new Error('statement is undefined');
    }
    statements.push(statement);
  };
  const saveStatement = function (statement, index) {
    if (statement === undefined || index === undefined) {
      throw new Error('statement or index is undefined');
    }
    statements[index] = statement;
  };
  const deleteStatement = function (index) {
    if (index === undefined) {
      throw new Error('index is undefined');
    }
    statements.splice(index, 1);
  };

  angular.forEach(MySearchesConfig.statements, (statement) => {
    statements.push(decorateStatementWithDistance(statement));
  });

  return {
    decorateStatementWithDistance,
    statements,
    addStatement,
    saveStatement,
    deleteStatement,
  };
}

function MySearchesResource($http, jrGlobalConfig) {
  return {
    addStatement(statement) {
      const params = {
        edit_w_add: statement.what,
        edit_l_add: statement.where,
        edit_r_add: statement.distance ? statement.distance.value : '',
      };
      return $http.post(`${jrGlobalConfig.baseUrl}mysearches/add`, undefined, {
        params,
      });
    },
    saveStatement(statement, index) {
      const params = {
        update_id: index,
      };
      params[`edit_w_${index}`] = statement.what;
      params[`edit_l_${index}`] = statement.where;
      params[`edit_r_${index}`] = statement.distance ? statement.distance.value : '';
      return $http.post(`${jrGlobalConfig.baseUrl}mysearches/save`, undefined, {
        params,
      });
    },
    deleteStatement(index) {
      const params = {
        edit_count: 10,
        delete_id: index,
      };
      return $http.post(`${jrGlobalConfig.baseUrl}mysearches/delete`, undefined, {
        params,
      });
    },
  };
}

function SearchFormService(
  SearchConfig,
  SearchOptionsService,
  PagesConfig,
  isUserComingFromSerpMethod
) {
  const isUserComingFromSerp = isUserComingFromSerpMethod(document.location.href);
  function getWhere() {
    if (PagesConfig.isHome && SearchConfig.whereByIp) {
      return SearchConfig.whereByIp;
    }

    return PagesConfig.isJobPreviewPage
      ? getJobPreviewWhere()
      : SearchConfig.rewrittenWhere || SearchConfig.where;
  }

  function getWhat() {
    return PagesConfig.isJobPreviewPage ? getJobPreviewWhat() : SearchConfig.what;
  }

  function getJobPreviewWhat() {
    return isUserComingFromSerp ? SearchConfig.what : SearchConfig.computedWhat;
  }

  function getJobPreviewWhere() {
    return isUserComingFromSerp
      ? SearchConfig.rewrittenWhere || SearchConfig.where
      : SearchConfig.computedWhere;
  }

  function isEmptySearch() {
    return PagesConfig.isJobPreviewPage ? getJobPreviewEmptySearch() : SearchConfig.isEmptySearch;
  }

  function getJobPreviewEmptySearch() {
    return isUserComingFromSerp ? SearchConfig.isEmptySearch : SearchConfig.isEmptyComputedSearch;
  }

  const search = {
    what: getWhat(),
    where: getWhere(),
    rewrittenWhere: SearchConfig.rewrittenWhere,
    rewrittenWhat: SearchConfig.rewrittenWhat,
    isEmptySearch: isEmptySearch(),
    country: SearchOptionsService.findCountry(SearchConfig.country),
    countries: SearchOptionsService.countries,
    distance: SearchOptionsService.findDistance(SearchConfig.radius.value),
    distances: SearchOptionsService.distances,
    isWhereInputEnabled: SearchConfig.isWhereInputEnabled,
    sortBy: SearchConfig.sortBy,
  };

  return {
    search,
  };
}

function SearchOptionsService(SearchFormOptionsConfig, SearchDistanceConfig, $filter) {
  const countries = angular.copy(SearchFormOptionsConfig.countriesOptions);
  const distances = [];

  if (SearchDistanceConfig.enabled) {
    angular.forEach(angular.copy(SearchFormOptionsConfig.distancesOptions), (distance) => {
      distance.selectedText = distance.text;
      if (distance.value === SearchDistanceConfig.autoDistanceText) {
        distance.text = distance.value;
        distance.selectedText = SearchDistanceConfig.measure;
      } else {
        distance.value = parseInt(distance.value, 10);
      }
      distances.push(distance);
    });
  }

  function findCountry(countryCode) {
    return (
      $filter('filter')(
        countries,
        {
          value: countryCode,
        },
        true
      )[0] || {}
    );
  }

  function findDistance(distance) {
    if (!distances.length) {
      return {};
    }
    const distanceValue = angular.isNumber(distance) ? distance : parseInt(distance, 10);
    const found = $filter('filter')(
      distances,
      {
        value: distanceValue,
      },
      true
    );

    return found.length ? found[0] : distances[0];
  }

  return {
    countries,
    distances,
    findCountry,
    findDistance,
  };
}
