import { getDomain } from '../../ts/service/email-service.ts';

angular
  .module('jrTracking', ['jrConfig'])
  .value('getDomain', getDomain)
  .factory('jtagService', [
    '$window',
    function ($window) {
      return {
        track(eventName, event, opts, href) {
          event.preventDefault();
          jtag('event', eventName, opts);

          $window.location.assign(href);
        },
      };
    },
  ])
  .directive('jtagAnalytics', [
    'jtagService',
    function (jtagService) {
      return {
        link(scope, elem, attrs) {
          Object.keys(jtagService).forEach((key) => {
            scope[key] = jtagService[key];
          });
        },
      };
    },
  ]);

if (!Element.prototype.matches) {
  Element.prototype.matches =
    Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
  Element.prototype.closest = function (s) {
    const el = this;
    let ancestor = this;
    if (!document.documentElement.contains(el)) {
      return null;
    }
    do {
      if (ancestor.matches(s)) {
        return ancestor;
      }
      ancestor = ancestor.parentElement;
    } while (ancestor !== null);
    return null;
  };
}
