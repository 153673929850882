import { searchUrlFormatter } from "@utils/formatters";

export default class NavigationService {

  public static toCountry(countryCode: string): void {
    window.location.href = `https://${countryCode.toLowerCase()}.jobrapido.com`;
  }

  public static toSearch(search: Readonly<SearchFormatter>, sortBy: string = window.jrConfigGlobal.search.sortBy): void {
    window.location.href = searchUrlFormatter(search, sortBy);
  }

  public static removeQueryStringParameter(key: string, urlParam = window.location.href): string {
    let url = urlParam;
    const hashParts = url.split('#');
    const regex = new RegExp(`([?&])${key}=.*?(&|#|$)`, 'i');

    if (hashParts[0].match(regex)) {
      url = hashParts[0].replace(regex, '$1');
      url = url.replace(/([?&])$/, '');

      if (typeof hashParts[1] !== 'undefined' && hashParts[1] !== null) {
        url += `#${hashParts[1]}`;
      }
    }

    return url;
  }
}