import { html, TemplateResult } from "lit/html.js";

export const TopToolsLinkMenuMobileComponent = (props: ITopToolsLinkMenu): TemplateResult => {

  const { messages: { HeaderMyProfile, HeaderMySearches, HeaderFavouriteJobs, MySearchesLogoutButton } } = props;


  return html`
        <a class="top-link jobseeker-link" data-google-interstitial="false" href="/myprofile">${HeaderMyProfile}</a>
        <a class="top-link jobseeker-link" data-google-interstitial="false" href="/mysearches">${HeaderMySearches}</a>
        <a class="top-link jobseeker-link" data-google-interstitial="false" href="/favouritejobs">${HeaderFavouriteJobs}</a>
        <a class="top-link jobseeker-link" data-google-interstitial="false" title="${MySearchesLogoutButton}"
          href="/logout">${MySearchesLogoutButton}</a>`;
}