type Timeout = {
  timeout?: number
}
type RequestInitWithTimeout = RequestInit & Timeout;

export const fetchWithTimeout = (uri: string, options: RequestInitWithTimeout) => {
  const DEFAULT_TIMEOUT = 5000;
  const controller = new AbortController();
  const { signal } = controller;
  const { timeout, ...fetchOptions } = options;

  const abortTimeout = setTimeout(() => {
    controller.abort();
  }, timeout || DEFAULT_TIMEOUT);

  return fetch(uri, { ...fetchOptions, signal })
    .then((response) => {
      clearTimeout(abortTimeout);

      return response;
    })
    .catch((error) => {
      if (error.name === 'AbortError') {
        throw new Error('Response timed out');
      }

      throw new Error(error.message);
    });
}
