import { html, TemplateResult } from "lit/html.js";
import { ifDefined } from "lit/directives/if-defined.js"

const LinkComponent = ({
  rel,
  href,
  target,
  cssClasses,
  linkText = '',
  eventHandlers
}: ILinkElement): TemplateResult => html`
    <a
      rel=${ifDefined(rel)}
      class=${ifDefined(cssClasses)}
      href=${href}
      target=${target}
      @click=${eventHandlers?.onClick}
    >
    ${linkText}
    </a>
  `

export default LinkComponent;