import { getScrollPercentage } from "@utils/index";
import { getRadius, getWhat, getWhere } from "./search-service";

const { pushNotifications: { scrollPercentage = 0 } } = window.jrConfigGlobal;
let canShowNotification = false;
if (window.Notification) {
  canShowNotification = window.Notification.permission === 'default';
}
let ScrollDebounce = true;


const getPushnamiCountryCode = (): string => {
  const { localeCountry = '' } = window.jrConfigGlobal;
  const contryCode = localeCountry === 'uk' ? 'gb' : localeCountry;

  return contryCode.toUpperCase()
}

export const pushnamiUpdate = (): void => {
  window.Pushnami.update({
    "country": getPushnamiCountryCode(),
    ...(getWhere() ? { "l": getWhere() } : window.jrConfigGlobal.search.where.resolvedByIp && { "l": window.jrConfigGlobal.search.where.resolvedByIp }),
    ...(getRadius() && { "r": getRadius() }),
    ...(getWhat() && { "w": getWhat() })
  }).prompt();
}

const onScroll = () => {
  if (ScrollDebounce && canShowNotification && scrollPercentage !== 0 && getScrollPercentage() >= scrollPercentage) {
    ScrollDebounce = false;

    const interval = setInterval(() => {
      if (typeof window.Pushnami !== 'undefined') {

        pushnamiUpdate();
        clearInterval(interval);

        document.removeEventListener('scroll', onScroll);
      }
    }, 500);
  }
}

export const promptPushnamiNotification = (): void => {
  document.addEventListener('scroll', onScroll);
}

export const activatePushnami = () => {
  if (window.jrConfigGlobal.pushNotifications.active && window.jrConfigGlobal.pushNotifications.scrollPercentage !== 0) {
    promptPushnamiNotification();
  } else if (window.jrConfigGlobal.pushNotifications.active && typeof window.Pushnami !== 'undefined') {
    pushnamiUpdate();
  }
}
