import { getDomain } from "./email-service";

export const pushEvent = (
  data: DataLayerObject,
  dataLayer: Record<string, unknown>[] | undefined = window.dataLayer,
  isGtmEnabled: boolean = window.jrConfigGlobal.feature.isGtmEnabled): void => {
  if (dataLayer && isGtmEnabled) {
    dataLayer.push(data);
  }
}

export const pushModalEvent = (
  eventData: DataLayerModalEvent,
  dataLayer: Record<string, unknown>[] | undefined = window.dataLayer,
  isGtmEnabled: boolean = window.jrConfigGlobal.feature.isGtmEnabled): void => {
  if (dataLayer && isGtmEnabled) {
    const {
      event,
      modalType = '',
      modalPopupPolicy = '',
      socialSource = '',
      email = ''
    } = eventData;

    dataLayer.push({
      event,
      modalType,
      modalPopupPolicy,
      socialSource,
      emailDomain: getDomain(email) || ''
    });
  }
}