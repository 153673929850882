import NavigationService from '../../ts/service/navigation-service.ts';

angular
  .module('jrLocationInput', ['jrConfig', 'jrTracking'])
  .value('NavigationService', NavigationService)
  .controller('LocationAutocompleteCtrl', [
    'SearchFormService',
    'NavigationService',
    '$scope',
    '$http',
    function (SearchFormService, NavigationService, $scope, $http) {
      $scope.typeaheadFirstItem = '';

      $scope.setWhereValue = function () {
        $scope.search = {
          where: SearchFormService.search.where,
        };
      };

      $scope.typeaheadOnSelect = function (location) {
        $scope.typeaheadFirstItem = '';

        if (!SearchFormService.search.what || !location) {
          return;
        }

        NavigationService.toSearch({
          what: SearchFormService.search.what,
          where: location,
        });
      };

      $scope.triggerTypeaheadOnSelect = () => {
        $scope.typeaheadFirstItem = '';

        const event = new Event('change');
        document.querySelector('.js-proposal-where').dispatchEvent(event);
      };

      $scope.getLocation = function (val, size) {
        return $http
          .post('/public/api/location/autocomplete', {
            prefix: val.toLowerCase(),
            size,
          })
          .then(function (response) {
            $scope.typeaheadFirstItem =
              response.data.length > 0 ? response.data[0].canonicalName : '';

            return response.data.map((item) => item.canonicalName);
          });
      };
    },
  ]);
